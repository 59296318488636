import BigNumber from 'bignumber.js';
import { defineStore } from 'pinia';
import { computed, reactive, watch } from 'vue';
import { ChainId } from '@/sdk/constants';
import { fromWei, toWei } from '@/sdk/utils';
import { BIG_ZERO } from '@/utils/bigNumber';
import { DEFAULT_CARDANO_CHAIN_ID } from '@/constants/DEFAULT_CARDANO_ID';
import { ISwapBestTrade } from './models/swap-best-trade.interface';
import { ISwapFormInput, ISwapFormOutput } from './models/swap-form-input.interface';
import { ISwapFormInfo } from '@/store/modules/swap/models/swap-form-info.interface';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { useBalances } from '@/store/modules/tokens/useBalances';
import { useSwap } from '@/store/modules/swap/useSwap';
import { useSwapMilkomedaWSCBridge } from '@/store/modules/swap/useSwapMilkomedaWSCBridge';
import { useSwapMilkomedaWSCUnwrapBridge } from '@/store/modules/swap/useSwapMilkomedaWSCUnwrapBridge';
import {
  BRIDGE_FEE_CARDANO_IN_ADA,
  BRIDGE_FEE_MILKOMEDA_IN_ADA,
  KEEP_AMOUNT_INTO_CARDANO_IN_ADA,
  MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA,
  MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA,
  SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA,
} from '../milkomeda-wrapped-smartcontract/milkomeda-wsc-calculation';
import { PERCENTAGE_DECIMAL_PRECISION } from '@/constants/INTERFACE_SETTINGS';

export const useSwapInfo = defineStore('swapInfo', () => {
  const { isPresentTokenIntoNetwork, getTokenByAddressAndChainId } = useTokens();
  const { balanceByTokenSymbolAndChainId } = useBalances();
  const { swapForm } = useSwap();
  const { milkomedaWSCBridgeState } = useSwapMilkomedaWSCBridge();
  const { milkomedaWSCUnwrapBridgeState } = useSwapMilkomedaWSCUnwrapBridge();

  const swapInfo = reactive<ISwapFormInfo>({
    crossChainFee: undefined,
    lpFee: undefined,
    priceImpact: undefined,
    price: undefined,
    priceInverse: undefined,
    minimumReceived: undefined,
    maximumSold: undefined,
    route: {
      path: [],
    },
    bridgeFee: undefined,
    bridgeLockUp: undefined,
    evmLockUp: undefined,
  });

  const isUnwrapADAFromMilkomeda = computed<boolean>(
    () => swapForm.isSwapSameTokensFromMilkomedaToCardano && !!swapForm.input.token?.isETHToken(),
  );

  function resetSwapInfoForBestTrade(): void {
    swapInfo.crossChainFee = undefined;
    swapInfo.lpFee = undefined;
    swapInfo.priceImpact = undefined;
    swapInfo.price = undefined;
    swapInfo.priceInverse = undefined;
    swapInfo.minimumReceived = undefined;
    swapInfo.maximumSold = undefined;
    swapInfo.route.path = [];
  }

  function $reset(): void {
    resetSwapInfoForBestTrade();
    swapInfo.bridgeFee = undefined;
    swapInfo.bridgeLockUp = undefined;
    swapInfo.evmLockUp = undefined;
  }

  // Update swapInfo when changed bestTrade
  watch(
    [
      () => swapForm.bestTrade,
      () => swapForm.input,
      () => swapForm.output,
      () => swapForm.settings.slippageTolerance,
    ],
    ([bestTrade, input, output, slippageTolerance]) => {
      if (Object.keys(bestTrade).length === 0) {
        resetSwapInfoForBestTrade();
        return;
      }

      let wrapUnwrapGas = false;
      if (swapForm.input.token && swapForm.output.token) {
        wrapUnwrapGas =
          swapForm.input.token.crossChainSymbol === swapForm.output.token.crossChainSymbol &&
          swapForm.input.token.chainId === swapForm.output.token.chainId;
      }

      swapInfo.crossChainFee =
        +bestTrade.route?.crossChainPortfolioIndex !== -1
          ? +fromWei(bestTrade.crossChainFee, input.token?.decimals).toString()
          : undefined;
      swapInfo.lpFee = +fromWei(bestTrade.lpFee, input.token?.decimals).toString();
      swapInfo.priceImpact = +fromWei(
        bestTrade.priceImpact,
        PERCENTAGE_DECIMAL_PRECISION,
      ).toString();
      swapInfo.price = +new BigNumber(output.amount || 1).div(input.amount || 1).toString();
      swapInfo.priceInverse = +new BigNumber(input.amount || 1).div(output.amount || 1).toString();
      swapInfo.minimumReceived = calculateMinimumReceived(bestTrade, output, {
        wrapUnwrapGas,
        slippageTolerance,
      });
      swapInfo.maximumSold = calculateMaximumSold(bestTrade, input, {
        wrapUnwrapGas,
        slippageTolerance,
      });
      swapInfo.route.path = (bestTrade.route?.path || []).map((address, index) => {
        const chainId: ChainId = bestTrade.route.chains[index] as unknown as ChainId;
        // NOTE
        // On PROD can be not implemented 'portfolioNames'
        const portfolioName = bestTrade.route.portfolioNames?.[index] ?? '';
        return {
          token: getTokenByAddressAndChainId(address, chainId),
          chainId: chainId,
          portfolioName,
        };
      });
    },
  );

  // BRIDGE FEE
  watch(
    [
      () => milkomedaWSCBridgeState.needBridge,
      () => milkomedaWSCUnwrapBridgeState.needRevertBridge,
    ],
    ([needBridge, needRevertBridge]) => {
      if (!needBridge && !needRevertBridge) {
        swapInfo.bridgeFee = undefined;
        return;
      }

      let fee = BIG_ZERO;
      if (needBridge) {
        fee = fee.plus(BRIDGE_FEE_CARDANO_IN_ADA);
      }
      if (needRevertBridge) {
        fee = fee.plus(BRIDGE_FEE_MILKOMEDA_IN_ADA);
      }

      swapInfo.bridgeFee = fee.toNumber();
    },
    { immediate: true },
  );

  // BRIDGE LOCK-UP
  watch(
    [
      isUnwrapADAFromMilkomeda,
      () => milkomedaWSCBridgeState.needBridge,
      () => milkomedaWSCUnwrapBridgeState.needRevertBridge,
    ],
    ([isUnwrapADA, needBridge, needRevertBridge]) => {
      if (isUnwrapADA || (!needBridge && !needRevertBridge)) {
        swapInfo.bridgeLockUp = undefined;
        return;
      }

      if (needRevertBridge) {
        swapInfo.bridgeLockUp = MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA;
        return;
      }

      if (needBridge) {
        swapInfo.bridgeLockUp = MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA;
        return;
      }

      swapInfo.bridgeLockUp = undefined;
    },
    { immediate: true },
  );

  // EVM LOCK-UP
  watch(
    () => swapForm.isSwapSameTokensFromMilkomedaToCardano,
    isSwapSameTokensFromMilkomedaToCardano => {
      if (!isSwapSameTokensFromMilkomedaToCardano) {
        swapInfo.evmLockUp = SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA;
        return;
      }

      swapInfo.evmLockUp = undefined;
    },
    { immediate: true },
  );

  function calculateWSCTotalFeesAndLookUpsWhenMaxADA() {
    const amounts: number[] = [];
    const balanceADAIntoCardanoInToken =
      balanceByTokenSymbolAndChainId(
        swapForm.input.token?.symbol ?? '',
        +DEFAULT_CARDANO_CHAIN_ID!,
      ).value?.balance.toExact() ?? 0;
    const isBalanceMoreThatKeepADAIntoCardano = BigNumber(balanceADAIntoCardanoInToken).gt(
      KEEP_AMOUNT_INTO_CARDANO_IN_ADA,
    );
    const needRevertBridge = isPresentTokenIntoNetwork(
      swapForm.output.token?.symbol ?? '',
      +DEFAULT_CARDANO_CHAIN_ID,
    );

    if (isBalanceMoreThatKeepADAIntoCardano) {
      amounts.push(BRIDGE_FEE_CARDANO_IN_ADA.toNumber());
    }
    if (needRevertBridge) {
      amounts.push(BRIDGE_FEE_MILKOMEDA_IN_ADA.toNumber());
    }
    if (needRevertBridge && !isUnwrapADAFromMilkomeda.value) {
      amounts.push(MIN_ADA_FOR_BRIDGE_FROM_MILKOMEDA_IN_ADA);
    }
    if (
      !needRevertBridge &&
      isBalanceMoreThatKeepADAIntoCardano &&
      !isUnwrapADAFromMilkomeda.value
    ) {
      amounts.push(MIN_ADA_FOR_BRIDGE_FROM_CARDANO_IN_ADA);
    }
    if (!swapForm.isSwapSameTokensFromMilkomedaToCardano) {
      amounts.push(SUM_OF_ALL_MILKOMEDA_GAS_IN_ADA);
    }

    return amounts.reduce((acc, fee) => acc.plus(fee), BIG_ZERO);
  }

  function isCrossChainRoute(bestTrade: ISwapBestTrade): boolean {
    return +bestTrade.route?.crossChainPortfolioIndex > -1;
  }

  function calculateMinimumReceived(
    bestTrade: ISwapBestTrade,
    output: ISwapFormOutput,
    { wrapUnwrapGas, slippageTolerance }: { wrapUnwrapGas: boolean; slippageTolerance: string },
  ): number | undefined {
    const tokenDecimals = output.token?.decimals;

    // NOTE:
    // We use `amountOut` from best trade, but when cross-chain and user input `TO`, we get `amountIn` into best trade.
    let amountOutInWei = bestTrade.amountOut;
    // NOTE:
    // When cross-chain and we get `amountIn` (user input `TO`), we can use user input as amount.
    if (!amountOutInWei && isCrossChainRoute(bestTrade)) {
      amountOutInWei = output.amount ? toWei(output.amount, tokenDecimals).toString() : undefined;
    }

    if (amountOutInWei) {
      // minimumReceived = amountOut / (1 + slippageTolerance / 100)
      return +fromWei(amountOutInWei, tokenDecimals)
        .div(new BigNumber(wrapUnwrapGas ? 0 : slippageTolerance).div(100).plus(1))
        .toString();
    }

    return undefined;
  }

  function calculateMaximumSold(
    bestTrade: ISwapBestTrade,
    input: ISwapFormInput,
    {
      wrapUnwrapGas,
      slippageTolerance,
    }: {
      wrapUnwrapGas: boolean;
      slippageTolerance: string;
    },
  ): number | undefined {
    const tokenDecimals = input.token?.decimals;

    const amountInInWei = bestTrade.amountIn;

    if (amountInInWei) {
      // maximumSold = amountIn * (1 + slippageTolerance / 100)
      return +fromWei(amountInInWei, tokenDecimals)
        .multipliedBy(new BigNumber(wrapUnwrapGas ? 0 : slippageTolerance).div(100).plus(1))
        .toString();
    }

    return undefined;
  }

  return {
    swapInfo,
    $reset,
    calculateWSCTotalFeesAndLookUpsWhenMaxADA,
  };
});
